import { AbstractControl } from '@angular/forms';

export class PasswordValidator {
  public static matchPassword(ac: AbstractControl) {
    const password = ac.get('password').value;
    const passwordConfirmation = ac.get('passwordConfirmation').value;
    if (password !== passwordConfirmation) {
      ac.get('passwordConfirmation').setErrors({ matchPassword: true });
    } else {
      return null;
    }
  }
}
